import { api } from "./api"

export const accessApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getActiveGroups: builder.query({
			query: () => "/access/activeGroups",
			providesTags: ["RootGroup", "Group"]
		}),
	})
})

export const {
	useGetActiveGroupsQuery
} = accessApi
