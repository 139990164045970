/**
 * Custom modal for `academyPlayersTestSuccess` and `talentPlayersTestSuccess` rule `profileMapObj` attribute value
 */

import { Form, Modal, Stack, Table } from "react-bootstrap"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetRootGroupClubSupportPlayersQuery } from "@api/rootGroup-api"
import { SortAlphaDown, SortAlphaUp, SortDown, SortNumericDown, SortNumericUp, SortUp } from "react-bootstrap-icons"

// Defined as a constant to allow specific header ordering
const testObjs = [
	{ _id: "622ee2bdb3892562acf85e8e", name: "SM-juoksu (jalkapallo)", unit: "s" },
	{ _id: "622ee285b3892562acf85e8a", name: "SM-pallo (jalkapallo)",  unit: "s" },
	{ _id: "622ee22cb3892562acf85e85", name: "Nopeus 30m", unit: "s" },
	{ _id: "622ee20d66cd5f563f6313de", name: "Nopeus 10m", unit: "s" },
	{ _id: "622ee1ab66cd5f563f6313d8", name: "Kevennyshyppy", unit: "cm" },
	{ _id: "622ee348b3892562acf85e97", name: "1200 (MAS)", unit: "s" },
	{ _id: "622ee239b3892562acf85e86", name: "Pujottelu", unit: "s" },
	{ _id: "622ee24cb3892562acf85e87", name: "Syöttö", unit: "s" }
]

const getAlphaSort = (path) => ({
	"false": (a,b) => _.get(b, path)?.localeCompare(_.get(a, path)),
	"true": (a,b) => _.get(a, path)?.localeCompare(_.get(b, path)),
})

const getNumSort = (path) => ({
	"false": (a,b) => _.get(b, path) - _.get(a, path),
	"true": (a,b) => _.get(a, path) - _.get(b, path)
})

const TestSuccessModal = ({ dataObj, curKey, handleClose }) => {

	const { t } = useTranslation()
	const params = useParams()
	const { rootGroupId } = params

	

	const { data: playersData } = useGetRootGroupClubSupportPlayersQuery({ id: rootGroupId })

	const getProfileName = pId => {
		const academyPlayers = _.get(playersData, "academyPlayers.players") ?? []
		const talentPlayers = _.get(playersData, "talentPlayers.players") ?? []
		const allPlayers = [...academyPlayers, ...talentPlayers]
		const match = allPlayers.find(o => o._id === pId)
		if (!match) {
			return pId
		}
		return `${match.lastname} ${match.firstname}`
	}

	const [sortLabel, setSortLabel] = useState("name")
	const [asc, setAsc] = useState(true)
	const [sortByTargetReached, setSortByTargetReached] = useState(false)
	
	const sortDic = useMemo(() => ({
		profileName: sortByTargetReached ? getNumSort("profileReached") : getAlphaSort("profileName"),
		...testObjs.reduce((a,b) => {
			const endPath = sortByTargetReached ? "targetReached" : "value"
			_.set(a, [b._id], getNumSort([b._id, endPath]))
			return a
		}, {})
	}), [sortByTargetReached])

	const rule = _.get(dataObj, "rule")

	const { profileMap } = dataObj ?? {}
	const testHeaders = testObjs.map(o => ({ testName: `${o.name} (${o.unit})`, _id: o._id }))

	const rowData = useMemo(() => {
		let rowData = []
		for (let profileId in profileMap) {
			const profileName = getProfileName(profileId)
			const testMap = profileMap[profileId]


			let processedDatum = { profileName }

			let targetReachedCount = 0
			for (let testId in testMap) {
				const data = testMap[testId]
				const { value, targetValue, demoLabel, targetReached } = data
				_.set(processedDatum, [testId], { value, targetValue, demoLabel, targetReached })
				targetReached && targetReachedCount++
			}

			processedDatum.profileReached = targetReachedCount >= 6

			rowData.push(processedDatum)
		}

		const sortFn = _.get(sortDic, [sortLabel, asc])
		if (sortFn) {
			rowData.sort(sortFn)
		}

		return rowData

	}, [playersData, profileMap, sortLabel, asc, sortByTargetReached])

	const targetReachedNumber = rowData.filter(r => _.values(r).filter(o => o.targetReached).length >= 6).length

	const handleHeaderClick = (key) => {
		setSortLabel(key)
		if (sortLabel === key) {
			setAsc(!asc)
		} else {
			setAsc(true)
		}
	}

	
	const getSortIcon = (key, isAlpha) => {
		if (sortLabel !== key) {
			return null
		}
		const descIcon = sortByTargetReached ? <SortDown /> : (isAlpha ? <SortAlphaUp /> : <SortNumericUp />)
		const ascIcon = sortByTargetReached ? <SortUp /> : (isAlpha ? <SortAlphaDown /> : <SortNumericDown />)
		return asc ? ascIcon : descIcon
	}


	const infoText = t(`Pelaaja saavuttaa tavoitetason mikäli hän pääsee kansalliselle tavoitetasolle (3) 6/8. 
	Huomioidaan paras tulos 6:kkn jaksolta ja testit on 
	pakko toteuttaa vähintään kerran jakson aikana. Mikäli ikäluokalle on määritelty kehitysvaihekohtaiset 
	taulukot, käytetään tavoitetason saavuttamisen arvioinnissa niitä.`)

	return (
		<Modal show={["academyPlayersTestSuccess", "talentPlayersTestSuccess"].includes(curKey)} onHide={handleClose} size="xl">
			<Modal.Header closeButton >
				<Modal.Title>{rule?.label}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					{infoText}
				</p>
				<Stack direction={"horizontal"} gap={2} className="mb-2" >
					<div className="fw-bold">
						<div>{t("Ehdon on toteuttanut {{count}}/{{max}} pelaajista", { count: targetReachedNumber, max: rowData.length})} {`(${dataObj?.value}%)`}</div>
					</div>
					<div className="ms-auto">
						<Form.Check 
							type="switch"
							reverse
							label={t("Järjestä tavoitetuloksen saavuttamisella")}
							checked={sortByTargetReached} 
							onChange={e => setSortByTargetReached(e.target.checked)}
						/>
					</div>
				</Stack>
				<div className="overflow-auto">
					<Table striped bordered>
						<thead>
							<tr>
								<th onClick={() => handleHeaderClick("profileName")} className="pointer">
									{t("Pelaaja")} {getSortIcon("profileName", true)}
								</th>
								{testHeaders.map((o, i) => (
									<th key={i} onClick={() => handleHeaderClick(o._id)} className="pointer">
										{o.testName} {getSortIcon(o._id)}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{rowData.map((r, i) => {
								const noColor = _.values(r).filter(o => o.value != null).length < 6
								const targetReached =  _.values(r).filter(o => o.targetReached).length >= 6
								const profileNameColor = noColor ? "unset" : (targetReached ? "green" : "red")
								return (
									<tr key={i}>
										<td style={{ color: profileNameColor }}>
											{r.profileName}
										</td>
										{testObjs.map(o => o._id).map((testId, j) => {
											const dataObj = _.get(r, testId)
											const { value, targetValue, demoLabel, targetReached } = dataObj ?? {}
											const color = targetReached ? "green" : "red"
											return (
												<td 
													key={j}
													title={targetValue == null ? undefined : `${t("Tavoitearvo on")} ${targetValue} (${demoLabel})`}
													style={{ color }}
												>
													{value}
												</td>
											)})}
									</tr>
								)
							})}
						</tbody>
					</Table>
				</div>
			</Modal.Body>
		</Modal>
	)
}



export default TestSuccessModal