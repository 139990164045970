import DynamicTabs from "@components/Common/DynamicTabs"
import React from "react"
import { useTranslation } from "react-i18next"
import SurveyChangeTab from "../../components/Charts/CoachSurvey/SurveyChangeTab"
import SurveyAnswersDistributionTab from "../../components/Charts/CoachSurvey/SurveyAnswersDistributionTab"
import SurveyGrowthTab from "../../components/Charts/CoachSurvey/SurveyGrowthTab"
import GroupContextSelect from "@components/Group/GroupContextSelect"
import InvalidAccess from "@components/Common/InvalidAccess"
import { useCoachLike } from "@utils/customization/customization"
import { useSelector } from "react-redux"
import { visualizationSelector } from "../../redux/visualization"
import { Col, Form, Row } from "react-bootstrap"
import GroupMemberSelectRedux from "../../components/Common/Inputs/GroupMemberSelectRedux"
import { LifeManagementChartsPlayerContent } from "./LifeManagementChartsPlayer"
import BackButton from "../../components/Charts/BackButton"

const LifeManagementChartsGroup = () => {
	const { t } = useTranslation()

	const { selectedProfileId } = useSelector(visualizationSelector)
	const isCoach = useCoachLike()

	if (!isCoach)
		return <InvalidAccess />

	return (
		<div>
			<BackButton />
			<h1>{t("Elämänhallinta")}</h1>
			<Row direction="horizontal" gap={2} className="mb-3 align-items-start">
				<Col>
					<Form.Group>
						<GroupContextSelect />
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<GroupMemberSelectRedux />
					</Form.Group>
				</Col>
			</Row>
			<br />
			<br />
			{selectedProfileId ? <LifeManagementChartsPlayerContent /> : <LifeManagementChartsGroupContent />}
		</div>
	)
}

const LifeManagementChartsGroupContent = () => {
	const { t } = useTranslation()
	const TAB_LABELS_SURVEYS = [{ id: "change", title: t("Muutos") }, { id: "distribution", title: t("Jakauma") }, { id: "development", title: t("Kehitys") }]

	return (
		<>
			<h2>{t("Urheilijan elämä ja ympäristö")}</h2>
			<DynamicTabs labels={TAB_LABELS_SURVEYS} useButtons className="w-100" >
				<SurveyChangeTab rowSize={2} pageName="coachSurveyLifeManagementChangeGraphs" />
				<SurveyAnswersDistributionTab pageName="coachSurveyLifeManagementDistributionGraphs" />
				<SurveyGrowthTab />
			</DynamicTabs>
		</>
	)
}

export default LifeManagementChartsGroup
