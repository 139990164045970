import { setSelectedProfileId, visualizationSelector } from "@redux/visualization"
import { useMembersInGroup } from "@utils/hooks"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { useIsWellbeingOperational } from "@utils/customization/customization"

const GroupMemberSelectRedux = ({ role, groupId }) => {

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const isWellbeing = useIsWellbeingOperational()

	const { selectedProfileId } = useSelector(visualizationSelector)
	const players = useMembersInGroup(role ?? (isWellbeing ? "hyvinvointi_osallistuja" : "player"), groupId ?? null)

	return (
		<Form.Group className="mb-3">
			<Form.Label>{isWellbeing ? t("Tarkastele yksittäistä osallistujaa") : t("Tarkastele yksittäistä pelaajaa")}</Form.Label>
			<Form.Select
				value={selectedProfileId ?? ""}
				onChange={e => {
					const v = e.target.value
					dispatch(setSelectedProfileId(v))
				}}
			>
				<option value="">-</option>
				{players.map(p => {
					const hasSuffix = p.profile.name && p.profile.sex
					const suffix = hasSuffix ? ` - ${t(`values|sex~${p.profile?.sex ?? p.sex}`)} ${_.floor(p.profile?.age ?? p.age)}v` : ""
					return <option key={p._id} value={p.profile?._id ?? p.profile}>{p.profile?.name ?? p.profileName}{suffix}</option>
				})}
			</Form.Select>
		</Form.Group>
	)
}


export default GroupMemberSelectRedux