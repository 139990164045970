// Components which serve as wrappers as part of a parent router
import { useGetProfileQuery } from "@api/profile-api"
import { logoutUser, userSelector } from "@redux/user"
import Loading from "@views/Loading"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import DisableRoleSelect from "@components/Navigation/DisableRoleSelect"
import { useHasAccessToFFAClubSupport, useIsWellbeingOperational } from "@utils/customization/customization"
import { lazy } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { lazyRetry } from "./utils"

export const LoginLayout = lazy(() => lazyRetry(() => import("../layouts/Login"), "loginLayout"))
export const MainLayout = lazy(() => lazyRetry(() => import("../layouts/Main"), "mainLayout"))

export const PrivateRoute = ({ children }) => {
	// Hard requirement of profile data initial load before PrivateRoute render
	const { profile, token } = useSelector(userSelector)
	const { isLoading, isError, error } = useGetProfileQuery(profile)
	const { t } = useTranslation()
	const location = useLocation()

	const dispatch = useDispatch()

	if (token) {
		if (isLoading) {
			return <Loading />
		}
		if (isError) {
			toast.error(t("Virhe profiilin latauksessa"), { id: "profile-error" })
			if (!error?.error?.includes("NetworkError")) {
				dispatch(logoutUser())
			}
			return <Navigate to="/login?autoLogin=0" />
		}
		return children
	} else {
		// Add redirect param to navigate
		// Login uses this to redirect to the paget he user was trying to access to before getting redirected for not being logged in
		const redirect = encodeURIComponent(location.pathname + location.search + location.hash)
		return <Navigate to={`/login?r=${redirect}`} />
	}
}

export const AdminOnlyRoute = ({ children }) => {
	const { isPlainAdmin, isAdmin } = useSelector(userSelector)
	if (isPlainAdmin) {
		return <>
			<DisableRoleSelect />
			{children}
		</>
	} else {
		if (isAdmin) {
			return <>
				<h1 className="text-danger">VIRHE</h1>
				{children}
			</>
		}
		return <Navigate to={"/"}/>
	}
}

// User must be in the wellbeing operational class
export const WellbeingWrapper= ({ children }) => {
	const { isPlainAdmin } = useSelector(userSelector)
	const isWellbeing = useIsWellbeingOperational()

	if (isWellbeing || isPlainAdmin) {
		return <>
			{children}
		</>
	} else {
		return null
	}
}

// User must be a coach and have a valid bucket level
export const FFAClubSupportWrapper = ({ children }) => {
	const hasAccess = useHasAccessToFFAClubSupport()

	if (!hasAccess) {
		return null
	}

	return <>{children}</>
}