import DynamicTabs from "@components/Common/DynamicTabs"
import React from "react"
import { useTranslation } from "react-i18next"
import SurveyChangeTab from "../../components/Charts/PlayerSurvey/SurveyChangeTab"
import { useIsPlayer, usePlayerLike } from "../../utils/customization/customization"
import InvalidAccess from "../../components/Common/InvalidAccess"
import BackButton from "../../components/Charts/BackButton"

const LifeManagementChartsPlayer = () => {
	const { t } = useTranslation()
	const isPlayerLike = usePlayerLike()

	if (!isPlayerLike) {
		return <InvalidAccess />
	}

	return (
		<div>
			<BackButton />
			<h1>{t("Elämänhallinta")}</h1>
			<br />
			<LifeManagementChartsPlayerContent />
		</div>
	)
}

const LifeManagementChartsPlayerContent = () => {
	const { t } = useTranslation()

	const TAB_LABELS_SURVEYS = [{ id: "change", title: t("Muutos") }, { id: "development", title: t("Kehitys") }]

	return (
		<>
			<h2>{t("Urheilijan elämä ja ympäristö")}</h2>
			<DynamicTabs labels={TAB_LABELS_SURVEYS} useButtons className="w-100" >
				<SurveyChangeTab pageGraph="playerSurveyLifeManagementChangeGraphs" />
				<div>{t("Tulossa myöhemmin")}</div>
			</DynamicTabs>
		</>
	)
}

export { LifeManagementChartsPlayer as default, LifeManagementChartsPlayerContent }
