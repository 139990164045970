import { SHOW_DEV_ONLY_COMPONENTS } from "@/const"
import React, { useEffect, useState } from "react"
import { Button, ButtonGroup, Tab, Tabs } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

/**
 * Automatically generates a tabbed view from component children and label array
 */
const DynamicTabs = ({ labels = [], disabledIndexes = [], children, setTab, tabState, className, useButtons }) => {

	if (!Array.isArray(children))
		children = [children]

	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const startTab = searchParams.get("tab")

	const [selectedTab, setSelectedTab] = useState(labels[startTab ?? 0])
	const selectedTabId = selectedTab.id

	const getTabIdByIndex = (index) => {
		if (!labels[index]) {
			return index
		}
		return labels[index].id
	}

	const getTabLabelByIndex = (index) => {
		if (!labels[index])
			return `${t("Välilehti")} ${index + 1}`
		return labels[index].title
	}

	const handleSelect = (key) => {
		setSelectedTab(key)
		if (setTab)
			setTab(key)
	}

	useEffect(() => {
		if (tabState) {
			handleSelect(tabState)
		}
	}, [tabState])

	if (useButtons) {
		return (
			<div>
				<ButtonGroup className={className}>
					{React.Children.map(children.filter(o => o != null), (node, index) =>
						<Button
							disabled={disabledIndexes.includes(index)}
							key={getTabIdByIndex(index)}
							onClick={() => setSelectedTab(labels[index])}
							active={getTabIdByIndex(index) === selectedTabId}
						>
							{getTabLabelByIndex(index)}
						</Button>
					)}
				</ButtonGroup>
				{<div>
					{React.Children.map(children.filter(o => o != null), (node, index) => {
						if (getTabIdByIndex(index) === selectedTabId) {
							return node
						} else {
							return null
						}
					})}
				</div>}
			</div>
		)
	}

	return (
		<Tabs activeKey={selectedTabId} onSelect={(k) => handleSelect(k)} id="dynamic-tabs" className={"mb-3 " + className}>
			{React.Children.map(children.filter(o => o != null), (node, index) =>
				<Tab
					disabled={disabledIndexes.includes(index)}
					key={getTabIdByIndex(index)}
					eventKey={getTabIdByIndex(index)}
					title={<div>{getTabLabelByIndex(index)}</div>}>
					{node}
				</Tab>
			)}
		</Tabs>
	)
}

export default DynamicTabs
