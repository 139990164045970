// Admin-only routes
import { lazy } from "react"
import { Route } from "react-router-dom"
import { AdminOnlyRoute, MainLayout, PrivateRoute } from "./wrappers"
import { lazyRetry } from "./utils"

const Users = lazy(() => lazyRetry(() => import("../views/User/Users"), "users"))
const EditUser = lazy(() => lazyRetry(() => import("../views/User/EditUser"), "editUser"))
const AddMember = lazy(() => lazyRetry(() => import("../views/RootGroup/AddMember"), "addMember"))
const AddMemberAsParent = lazy(() => lazyRetry(() => import("../views/User/AddMemberAsParent"), "addMemberAsParent"))
const GlobalTemplateRoleList = lazy(() => lazyRetry(() => import("../views/TemplateRole/GlobalTemplateRoleList"), "globalTemplateRoleList"))
const GlobalTemplateRoleEdit = lazy(() => lazyRetry(() => import("../views/TemplateRole/GlobalTemplateRoleEdit"), "globalTemplateRoleEdit"))
const GlobalTemplateRoleCreate = lazy(() => lazyRetry(() => import("../views/TemplateRole/GlobalTemplateRoleCreate"), "globalTemplateRoleCreate"))
const RootGroupClassList = lazy(() => lazyRetry(() => import("../views/RootGroupClass/RootGroupClassList"), "rootGroupClassList"))
const RootGroupClassCreate = lazy(() => lazyRetry(() => import("../views/RootGroupClass/RootGroupClassCreate"), "rootGroupClassCreate"))
const RootGroupClassEdit = lazy(() => lazyRetry(() => import("../views/RootGroupClass/RootGroupClassEdit"), "rootGroupClassEdit"))
const CustomerGroupCreate = lazy(() => lazyRetry(() => import("../views/RootGroupClass/CustomerGroupCreate"), "customerGroupCreate"))
const CustomerGroupEdit = lazy(() => lazyRetry(() => import("../views/RootGroupClass/CustomerGroupEdit"), "customerGroupEdit"))
const Logs = lazy(() => lazyRetry(() => import("../views/Admin/Logs"), "logs"))
const AccountMerge = lazy(() => lazyRetry(() => import("../views/Admin/AccountMerge"), "accountMerge"))
const Notifications = lazy(() => lazyRetry(() => import("../views/Admin/Notifications"), "notifications"))
const NotificationForm = lazy(() => lazyRetry(() => import("../views/Admin/NotificationForm"), "notificationForm"))
const TaskDefinitionList = lazy(() => lazyRetry(() => import("../views/Tasks/TaskDefinition/TaskDefinitionList"), "taskDefinitionList"))
const TaskDefinitionEditOrCreate = lazy(() => lazyRetry(() => import("../views/Tasks/TaskDefinition/TaskDefinitionEditOrCreate"), "taskDefinitionEditOrCreate"))
const SurveyList = lazy(() => lazyRetry(() => import("../views/Survey/SurveyList"), "surveyList"))
const SurveyEditOrCreate = lazy(() => lazyRetry(() => import("../views/Survey/SurveyEditOrCreate"), "surveyEditOrCreate"))
const SurveyTypeList = lazy(() => lazyRetry(() => import("../views/Survey/SurveyTypeList"), "surveyTypeList"))
const SurveyTypeEditOrCreate = lazy(() => lazyRetry(() => import("../views/Survey/SurveyTypeEditOrCreate"), "surveyTypeEditOrCreate"))
const PageCustomization = lazy(() => lazyRetry(() => import("../views/RootGroupClass/PageCustomization"), "pageCustomization"))
const VideoGeneral = lazy(() => lazyRetry(() => import("../views/Video/VideoGeneral"), "videoGeneral"))
const TemplateList = lazy(() => lazyRetry(() => import("../views/PeriodPlanner/Template/TemplateList"), "templateList"))
const TemplateEditOrCreate = lazy(() => lazyRetry(() => import("../views/PeriodPlanner/Template/TemplateEditOrCreate"), "templateEditOrCreate"))
const Debug = lazy(() => lazyRetry(() => import("../views/Admin/Debug"), "adminDebug"))
const DirectVideoUpload = lazy(() => lazyRetry(() => import("../views/Admin/Debug/DirectVideoUpload"), "directVideoUpload"))
const GetActiveHandles = lazy(() => lazyRetry(() => import("../views/Admin/Debug/GetActiveHandles"), "getActiveHandles"))
const HeapDump = lazy(() => lazyRetry(() => import("../views/Admin/Debug/HeapDump"), "heapDump"))
const WebSocketTest = lazy(() => lazyRetry(() => import("../views/Admin/Debug/WebSocketTest"), "webSocketTest"))
const CsvDownload = lazy(() => lazyRetry(() => import("../views/Admin/Debug/CsvDownload"), "CsvDownload"))
const EventTypeList = lazy(() => lazyRetry(() => import("../views/Admin/EventTypeList"), "eventTypeList"))
const EventTypeDetail = lazy(() => lazyRetry(() => import("../views/Admin/EventTypeDetail"), "EventTypeDetail"))
const EventTypeClassDetail = lazy(() => lazyRetry(() => import("../views/Admin/EventTypeClassDetail"), "EventTypeClassDetail"))
const PushNotificationsControlPanel = lazy(() => lazyRetry(() => import("../views/Admin/PushNotificationsControlPanel"), "PushNotificationsControlPanel"))
const ImportUsersFromCSV = lazy(() => lazyRetry(() => import("../views/Admin/ImportUsersFromCSV"), "importUsersFromCSV"))

const AdminRoute = <Route
	element={
		<PrivateRoute>
			<AdminOnlyRoute>
				<MainLayout fullWidth />
			</AdminOnlyRoute>
		</PrivateRoute>
	}
>
	<Route path="/video/general" element={<VideoGeneral />} />
	<Route path="/add_member_to_group" element={<AddMember />} />
	<Route path="/add_member_as_parent" element={<AddMemberAsParent />} />
	<Route path="/admin/logs" element={<Logs />} />
	<Route path="/admin/notifications" element={<Notifications />} />
	<Route path="/admin/notifications/create" element={<NotificationForm />} />
	<Route path="/admin/notifications/:id" element={<NotificationForm edit />} />
	<Route path="/admin/push_notifications" element={<PushNotificationsControlPanel />} />
	<Route path="/admin/merge" element={<AccountMerge />} />
	<Route path="/survey" element={<SurveyList />} />
	<Route path="/survey/types" element={<SurveyTypeList />} />
	<Route path="/survey/types/:id" element={<SurveyTypeEditOrCreate />} />
	<Route path="/survey/:id" element={<SurveyEditOrCreate />} />
	<Route path="/admin/tasks" element={<TaskDefinitionList />} />
	<Route path="/admin/tasks/:id" element={<TaskDefinitionEditOrCreate />} />
	<Route path="/root_group_class" element={<RootGroupClassList />} />
	<Route path="/root_group_class/create" element={<RootGroupClassCreate />} />
	<Route path="/root_group_class/:id" element={<RootGroupClassEdit />} />
	<Route path="/root_group_class/:id/pageCustomization" element={<PageCustomization />} />
	<Route path="/customer_group/create" element={<CustomerGroupCreate />} />
	<Route path="/customer_group/:id" element={<CustomerGroupEdit />} />
	<Route path="/template_roles" element={<GlobalTemplateRoleList />} />
	<Route path="/template_roles/create" element={<GlobalTemplateRoleCreate />} />
	<Route path="/template_roles/:id" element={<GlobalTemplateRoleEdit />} />
	<Route path="/users" element={<Users />} />
	<Route path="/users/:id" element={<EditUser />} />
	<Route path="/period_planner/template" element={<TemplateList />} />
	<Route path="/period_planner/template/:id" element={<TemplateEditOrCreate />} />
	<Route path="/admin/debug" element={<Debug />} />
	<Route path="/admin/debug/direct_video_upload" element={<DirectVideoUpload />} />
	<Route path="/admin/debug/activeHandles" element={<GetActiveHandles />} />
	<Route path="/admin/debug/heapDump" element={<HeapDump />} />
	<Route path="/admin/debug/webSocketTest" element={<WebSocketTest />} />
	<Route path="/admin/debug/csv" element={<CsvDownload />} />
	<Route path="/admin/event_types" element={<EventTypeList />} />
	<Route path="/admin/event_types/classes/:id" element={<EventTypeClassDetail />} />
	<Route path="/admin/event_types/:id" element={<EventTypeDetail />} />
	<Route path="/admin/import_users_from_csv" element={<ImportUsersFromCSV />} />
</Route>

/*
const adminRouter = {
	{
		element: <PrivateRoute>
		<AdminOnlyRoute>
			<MainLayout fullWidth />
		</AdminOnlyRoute>
	</PrivateRoute>,
		children: [
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/add_member_to_group", element: <AddMemberAsParent />},
			{ path: "/add_member_as_parent", element: <AddMemberAsParent />},
			{ path: "/admin/notifications", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
			{ path: "/video/general", element: <VideoGeneral />},
		]
>
	<Route path="/add_member_to_group" element={<AddMember />} />
	<Route path="/add_member_as_parent" element={<AddMemberAsParent />} />
	<Route path="/admin/logs" element={<Logs />} />
	<Route path="/admin/notifications" element={<Notifications />} />
	<Route path="/admin/notifications/create" element={<NotificationForm />} />
	<Route path="/admin/notifications/:id" element={<NotificationForm edit />} />
	<Route path="/admin/merge" element={<AccountMerge />} />
	<Route path="/survey" element={<SurveyList />} />
	<Route path="/survey/types" element={<SurveyTypeList />} />
	<Route path="/survey/types/:id" element={<SurveyTypeEditOrCreate />} />
	<Route path="/survey/:id" element={<SurveyEditOrCreate />} />
	<Route path="/admin/tasks" element={<TaskDefinitionList />} />
	<Route path="/admin/tasks/:id" element={<TaskDefinitionEditOrCreate />} />
	<Route path="/root_group_class" element={<RootGroupClassList />} />
	<Route path="/root_group_class/create" element={<RootGroupClassCreate />} />
	<Route path="/root_group_class/:id" element={<RootGroupClassEdit />} />
	<Route path="/root_group_class/:id/pageCustomization" element={<PageCustomization />} />
	<Route path="/customer_group/create" element={<CustomerGroupCreate />} />
	<Route path="/customer_group/:id" element={<CustomerGroupEdit />} />
	<Route path="/template_roles" element={<GlobalTemplateRoleList />} />
	<Route path="/template_roles/create" element={<GlobalTemplateRoleCreate />} />
	<Route path="/template_roles/:id" element={<GlobalTemplateRoleEdit />} />
	<Route path="/users" element={<Users />} />
	<Route path="/users/:id" element={<EditUser />} />
	<Route path="/period_planner/template" element={<TemplateList />} />
	<Route path="/period_planner/template/:id" element={<TemplateEditOrCreate />} />
	<Route path="/admin/debug" element={<Debug />} />
	<Route path="/admin/debug/direct_video_upload" element={<DirectVideoUpload />} />
	<Route path="/admin/debug/activeHandles" element={<GetActiveHandles />} />
	<Route path="/admin/debug/heapDump" element={<HeapDump />} />
	}
}
*/

export default AdminRoute
