import { convertObjToQueryStr } from "@utils/utils"
import { api } from "./api"
import _ from "lodash"

export const rootGroupApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMyRootGroups: builder.query({
			query: () => "/root_group/own",
			providesTags: (result, error, args) => {
				return result.map(doc => ({type: "RootGroup", id: doc._id})).concat([{type: "RootGroup", id: "LIST"}])
			}
		}),
		getRootGroupsByProcess: builder.query({
			query: (rootGroupId) => `/root_group/process/${rootGroupId}`
		}),
		searchRootGroups: builder.query({
			query: (queryObj) => {
				if (queryObj.query) {
					queryObj = queryObj.query
				}
				return `/root_group/search${convertObjToQueryStr(queryObj)}`
			},
			providesTags: (result, error, arg) => {
				return ["RootGroup", "Group"]
			}
		}),
		getRootGroup: builder.query({
			query: ({ id, paginationOpts }) => `/root_group/${id}${convertObjToQueryStr(paginationOpts)}`,
			providesTags: (result, error, args) => {
				return [{type: "RootGroup", id: args.id}, "RootGroup"]
			}
		}),
		getRootGroups: builder.query({
			query: (queryObj) => `/root_group${convertObjToQueryStr(queryObj)}`,
			providesTags: (result, error, args) => {
				return result.map(doc => ({ type: "RootGroup", id: doc._id })).concat([{type: "RootGroup", id: "LIST"}])
			}
		}),
		createRootGroup: builder.mutation({
			query: (body) => ({
				url: "/root_group",
				method: "POST",
				body: body
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "RootGroup", id:"LIST"}]
			}
		}),
		updateRootGroup: builder.mutation({
			query: ({id, body}) => ({
				url: `/root_group/${id}`,
				method: "PUT",
				body: body
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "RootGroup", id: args.id}]
			}
		}),
		getRootGroupClubSupportPlayers: builder.query({
			query: ({id}) => ({
				url: `/root_group/${id}/club-support/players`,
				method: "GET",
			}),
			providesTags: (result, error, args) => ([{ type: "ClubSupportPlayers", id: args.id }])
		}),
		updateRootGroupClubSupportPlayers: builder.mutation({
			query: ({id, body, query}) => ({
				url: `/root_group/${id}/club-support/players`,
				method: "PUT",
				body: body,
				params: query
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "ClubSupportPlayers", id: args.id}]
			}
		}),
		unlockRootGroupClubSupportPlayersByField: builder.mutation({
			query: ({id, body, query}) => ({
				url: `/root_group/${id}/club-support/players/unlock`,
				method: "POST",
				body: body,
				params: query
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "ClubSupportPlayers", id: args.id }]
			}
		}),
		deleteRootGroup: builder.mutation({
			query: (id) => ({
				url: `/root_group/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "RootGroup", id: args.id}]
			}
		}),
		getSubGroups: builder.query({
			query: (id) => `/root_group/${id}/groups`,
			providesTags: (result, error, arg) => {
				return result.map(doc => ({type: "Group", id: doc._id})).concat([{type: "Group", id: "LIST"}]).concat([{type: "RootGroup", id: arg}])
			}
		}),
		addRootGroupPatron: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/patron`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		editRootGroupPatron: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/patron`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		deleteRootGroupPatron: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/patron`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		addRootGroupMember: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/member`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		deleteRootGroupMember: builder.mutation({
			query: ({ id, profileId, body }) => ({
				url: `/root_group/${id}/member/${profileId}`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }, { type: "Profile", id: arg.profileId}]
			}
		}),
		deleteRootGroupMembers: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/member`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }].concat(arg?.body?.profileIds?.map(pId => ({ type: "Profile", id: pId})) ?? [])
			}
		}),
		cancelRootGroupInvite: builder.mutation({
			query: ({ rootId, groupId, inviteId, profile }) => ({
				url: `/root_group/${rootId}/cancelInvite/${inviteId}`,
				method: "POST",
			}),
			invalidatesTags: (result, error, arg) => {
				return _.compact([
					{ type: "RootGroup", id: arg.rootId },
					arg.groupId && { type: "Group", id: arg.groupId },
					arg.profile && { type: "Profile", id: arg.profile}
				])
			}
		}),
		setPassiveRootGroup: builder.mutation({
			query: ({ rootGroupId, groups }) => ({
				url: `/root_group/${rootGroupId}/setPassive`,
				method: "POST",
				body: { groups }
			}),
			invalidatesTags: (result, error, arg) => {
				return ["RootGroup", "Group", "Profile"]
			}
		}),
		unsetPassiveRootGroup: builder.mutation({
			query: ({ rootGroupId, groups }) => ({
				url: `/root_group/${rootGroupId}/unsetPassive`,
				method: "POST",
				body: { groups }
			}),
			invalidatesTags: (result, error, arg) => {
				return ["RootGroup", "Group", "Profile"]
			}
		}),
		resendEmail: builder.mutation({
			query: ({ rootGroupId, body, groupId }) => ({
				url: `/root_group/${rootGroupId}/resendemail`,
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, arg) => {
				let arr = [{ type: "RootGroup", id: arg.rootGroupId }]
				if(arg.groupId) {
					arr.push({ type: "Group", id: arg.groupId })
				}
				return arr
			}
		}),
		addRoleMember: builder.mutation({
			query: ({ rootId, body }) => ({
				url: `/root_group/${rootId}/roleMember`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.rootId }]
			}
		}),
		removeRoleMember: builder.mutation({
			query: ({ rootId, profileId, body }) => ({
				url: `/root_group/${rootId}/roleMember/${profileId}`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.rootId }, { type: "Profile", id: arg.profileId }]
			}
		})
	})
})

export const {
	useGetMyRootGroupsQuery,
	useGetRootGroupsQuery,
	useGetRootGroupsByProcessQuery,
	useCreateRootGroupMutation,
	useUpdateRootGroupMutation,
	useGetRootGroupClubSupportPlayersQuery,
	useUpdateRootGroupClubSupportPlayersMutation,
	useUnlockRootGroupClubSupportPlayersByFieldMutation,
	useDeleteRootGroupMutation,
	useGetRootGroupQuery,
	useGetSubGroupsQuery,
	useAddRootGroupPatronMutation,
	useEditRootGroupPatronMutation,
	useDeleteRootGroupPatronMutation,
	useAddRootGroupMemberMutation,
	useDeleteRootGroupMemberMutation,
	useDeleteRootGroupMembersMutation,
	useCancelRootGroupInviteMutation,
	useSearchRootGroupsQuery,
	useLazySearchRootGroupsQuery,
	useSetPassiveRootGroupMutation,
	useUnsetPassiveRootGroupMutation,
	useResendEmailMutation,
	useAddRoleMemberMutation,
	useRemoveRoleMemberMutation
} = rootGroupApi
